import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  GridItem,
  Heading,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  OrderedList,
  SimpleGrid,
  Spinner,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useVisas } from "../../hooks/useVisas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { useAdministrativeSupport } from "../../hooks/useAdministrativeSupport";
import SidebarList from "../../components/common/SidebarList";
import ListSidebar from "../../components/common/ListSidebar";
import Documents from "../../components/common/Documents";

const AdministrativeSupport = () => {
  const navigate = useNavigate();
  const { getAdministrativeSupport } = useAdministrativeSupport();
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const getData = async () => {
    setLoading(true);
    const { error = null, data } = await getAdministrativeSupport();

    if (!error) {
      setData(data);
    } else {
      setError(true);
    }

    setLoading(false);
  };
  const filterDocuments = (documents: any, value: boolean) => {
    const result: any[] = documents?.filter(
      ({ toUpload }: { toUpload: boolean }) => toUpload === value
    );
    return result;
  };
  useEffect(() => {
    getData();
  }, []);

  if (isLoading)
    return (
      <Center height={"100vh"}>
        <Spinner size={"xl"} />
      </Center>
    );

  const sidebarList = [
    "Nos services",
    "Frais",
    "Documents a fournir",
    "Postuler",
  ];

  return (
    <Container maxW={"6xl"} mt={20}>
      <Box mt={4}>
        <Heading size={"md"}>{data?.title}</Heading>
        {/* <Text>{data?.description}</Text> */}
      </Box>
      <SimpleGrid columns={3} spacing={2} my={6}>
        <GridItem colSpan={[0, 1]} display={["none", "block"]}>
          <ListSidebar items={sidebarList} />
        </GridItem>
        <GridItem colSpan={[3, 2]} position={"relative"} my={2}>
          <Box>
            <Heading size={"lg"} color={"primary"}>
              Description :
            </Heading>
            <Box
              dangerouslySetInnerHTML={{ __html: data?.description || "" }}
            />
          </Box>
          <Box>
            <Heading size={"lg"} color={"primary"}>
              Nos services :
            </Heading>
            <Box dangerouslySetInnerHTML={{ __html: data?.services || "" }} />
          </Box>
          <Box>
            <Heading size={"lg"} color={"primary"}>
              Frais :
            </Heading>
            <UnorderedList py={2} px={8}>
              {data?.fees.map(
                ({ label, value }: { label: string; value: string }) => (
                  <>
                    <ListItem>
                      {label} : {value}
                    </ListItem>
                  </>
                )
              )}
            </UnorderedList>
          </Box>
          <Box my={6}>
            <Heading size={"lg"} color={"primary"} my={3}>
              Dossier a fournir :
            </Heading>
            <Heading size={"sm"}>Ces documents sont à télécharger</Heading>
            <Documents documents={filterDocuments(data?.documents, true)} />

            <Heading size={"sm"} mt={3}>
              Ces documents reste chez toi et ne sont pas à télécharger
            </Heading>
            <Documents documents={filterDocuments(data?.documents, false)} />
          </Box>
          <Alert status="error" rounded={"md"} my={1}>
            <AlertIcon />
            les inscriptions sont fermées
          </Alert> 
          <Button
            className="sticky-button"
            bg={"primary"}
            color={"white"}
            w={"full"}
            position={"sticky"}
            bottom={10}
            onClick={() => {
              navigate(`/administrative-support/applications/apply`);
            }}
            isDisabled
          >
            Postuler maintenant
          </Button>
        </GridItem>
      </SimpleGrid>
    </Container>
  );
};

export default AdministrativeSupport;
